@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  *{
    @apply box-border
  }
  body {
    @apply bg-slate-100 p-0 m-0 overflow-x-hidden
  }
  .container {
    @apply mx-auto w-screen min-h-screen max-w-[500px]
  }
  .container_login {
    @apply container flex flex-col justify-start items-center bg-blue-800 bg-gradient-to-t from-blue-900 to-blue-600
  }
  .container_mobile {
    @apply container flex flex-col p-4
  }
  .login_form {
    @apply w-[320px] flex flex-col mt-[200px] gap-4
  }
  .form_control {
    @apply flex flex-col gap-2
  }
  .form_label {
    @apply text-white
  }
  .form_input{
    @apply h-12 px-3 w-full rounded-lg outline-none
    h-12 px-3 w-full rounded-lg outline-none
  }
  .form_input_icon {
    @apply relative flex items-center
  }
  .form_input_icon_item{
    @apply absolute right-0 w-12 h-12 flex justify-center items-center
  }
  .form_helper {
    @apply mt-2 text-red-500
    mt-2 text-red-500
  }

  .button_login {
    @apply h-12 bg-red-500 text-white w-full rounded-lg
  }

  .search_input {
    @apply h-12 px-3 outline-none border-[.5px] border-gray-300 rounded-lg w-full
             
  }

  /* BUTTON NAVIGATION */
  .button_nav_container{
    @apply flex w-full h-12 fixed bottom-0 left-0 bg-gradient-to-tr from-blue-800 to-blue-600 rounded-t-xl text-xl
  }
  .button_nav_item {
    @apply h-full flex-1 flex flex-col justify-center items-center text-white
  }
  .button_nav_item_center {
    @apply h-full flex-1 flex flex-col justify-center items-center text-white relative
  }

  .center_icon {
    @apply absolute bottom-[22px] bg-red-600 text-white w-16 h-16 flex justify-center items-center rounded-full shadow-lg bg-gradient-to-tr from-red-900 to-red-500 border-[4px] border-white text-3xl
  }

  /* FORM P2TL */
  .form_p2tl{
    @apply pt-14 flex flex-col gap-4 mb-12
  }

  .form_group_p2tl{
    @apply flex flex-col gap-2
    flex flex-col gap-2
  }

  .form_input_p2tl{
    @apply h-12 px-3 border-[.5px] border-gray-300 rounded-lg outline-none w-full
  }

  .form_textarea_p2tl{
    @apply h-[150px] p-3 border-[.5px] border-gray-300 rounded-lg outline-none w-full
  }

  .navigation_p2tl {
    @apply flex w-full items-center justify-center mt-12 gap-4 fixed bottom-0 left-0 h-14 w-full
  }

  .navigation_p2tl_item_active {
    @apply h-10 w-10 rounded-full flex justify-center items-center bg-blue-700 text-white
  }

  .navigation_p2tl_item_disable {
    @apply h-10 w-10 rounded-full flex justify-center items-center bg-slate-300 text-white
  }
  
}